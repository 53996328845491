/** @jsx jsx */
import { jsx } from 'theme-ui';
import Layout from '../components/layout';
import FullScreen from '../components/layout/fullScreen'
import Wrapper from '../components/layout/wrapper'
import LoginForm from '../components/loginForm'
import MetaData from '../components/metadata'

const Login = (props) => {
  
  return (
    <Layout {...props} plain>
      <MetaData title="Login" />
      <FullScreen backgroundColor="background">
        <Wrapper>
          <LoginForm {...props} />
        </Wrapper>
      </FullScreen>
    </Layout>
  )
}

export default Login