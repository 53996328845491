/** @jsx jsx */
import { useState } from 'react'
import { jsx, Card, Label, Box, Input, Button, Text } from 'theme-ui';
import { confirmSignup } from '../../utils/auth'
import styles from './styles'

const CodeVerificationForm = (props) => {

  const [code, setCode] = useState('')
  const [error, setError] = useState([])

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(false);
    const request = await confirmSignup(props.username, code)
    if (request.success) {
      props.onSuccess()
    } else {
      console.log('Error', request);
      setError([{field: 'global', value: request.error?.message}])
    }
  }

  const Error = ({type, className}) => {
    if (!error || !Array.isArray(error))
      return null

    const found = error.find((x) => {
      return x.field === type
    })

    if (!found)
      return null

    return <Text className={className} sx={styles.error}>{found.value}</Text>
  }

  return (
    <Card 
      as="form"
      onSubmit={handleSubmit}
    >
      <Error type="global" sx={{mb: 2}} />
      <Box mb={2}>
        <Label mb={2}>Code we just sent you:</Label>
        <Input type="text" name='code' value={code} onChange={e => setCode(e.target.value)} />
        <Error type="code" />
      </Box>
      <Button type="submit">Submit</Button>
    </Card>
  )
}

export default CodeVerificationForm