/** @jsx jsx */
import { jsx, Heading, Box, Input, Label, Text, Button, Flex, Alert, Spinner } from 'theme-ui';
import React, { useState } from 'react'
import Inner from '../layout/inner'
import Logo from '../../images/fgr-logo.png'
import { Powered } from '../footer'
import { login, setUser, isLoggedIn, getIdToken, resendVerificationCode } from "../../utils/auth"
import update from 'immutability-helper';
import { navigate, Link } from 'gatsby';
import queryString from 'query-string';
import styles from './styles'
import Modal, { Overlay, Close } from '../modal'
import { responsive, sanitise } from '../../utils/helpers'
import CodeVerificationForm from '../codeVerificationForm'

const LoginForm = (props) => {
  
  const [state, setState] = useState({
    email: '',
    password: ''
  });
  const [resendVerification, setResendVerification] = useState(false)
  const [codeResent, setCodeResent] = useState(false)
  const queryParams = queryString.parse(props.location.search)
  const [error, setError] = useState(queryParams?.error_description ? {field: 'global', value: queryParams.error_description} : false)
  const [success, setSuccess] = useState(false)

  const doRedirect = async () => {
    if (!queryParams.returnUrl && !queryParams.return)
      return
    // Redirect for StreamAMG SSO
    if (queryParams.returnUrl) {
      const token = await getIdToken()
      if (token) {
        const redirect = `${queryParams.returnUrl.replace('startbasket', 'start')}&token=${token}`
        window.location.href = redirect;
      }
    }
    // Redirect for internal links
    if (queryParams.return) {
      navigate(queryParams.return)
    }
  }

  if (queryParams.returnUrl && isLoggedIn()) {
    doRedirect()
    return <Spinner sx={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }} />
  }

  if (isLoggedIn() && !queryParams.returnUrl) {
    navigate('/')
    return null
  }

  const validateFields = () => {
    const required = [
      'email',
      'password'
    ]

    let errors = false

    required.forEach((x) => {
      if (!state[x] || state[x] === '') {
        if (!errors) {
          errors = []
        }
        errors.push({
          field: x,
          value: 'This field is required'
        })
        return true
      }
    })
    setError(errors)
    return errors
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false)

    const validationErrors = validateFields()
    if (validationErrors)
      return

    const sanitisedEmail = sanitise(state.email)
    await login(sanitisedEmail, state.password).then(async (user) => {
      setUser(user)
      if (queryParams.returnUrl || queryParams.return) {
        doRedirect()
      } else {
        navigate("/my-fgr")
      }
    }).catch((err) => {
      let showError = true
      console.log('ERROR', err);
      try {
        if (err.toLowerCase() === 'user is not confirmed.') {
          setResendVerification(true)
          showError = false
        }
      } catch (error) {
        showError = true
      }
      
      if (!showError) return
      setError({
        field: 'global', 
        value: err
      })
    });
  }

  const handleChange = (e) => {
    setState(update(state, {
      [e.target.name]: {
        $set: e.target.value
      }
    }))
  }

  const Error = ({type}) => {
    if (!error || !Array.isArray(error))
      return null

    const found = error.find((x) => {
      return x.field === type
    })

    if (!found)
      return null

    return <Text sx={styles.error}>{found.value}</Text>
  }

  const handleResendVerificationCode = async (e) => {
    e.preventDefault();
    const request = await resendVerificationCode(state.email)
    if (!request.success) {
      setError({
        field: 'global',
        error: `Couldn\'t send to ${state.email}, please contact reception@fgr.co.uk for further assistance`
      })
      return
    }
    setCodeResent(true)
  }

  const handleCodeVerificationSuccess = () => {
    setSuccess('Your email address has been verified, please try to log in')
    setCodeResent(false)
    setResendVerification(false)
  }

  return (
      <Inner sx={styles.inner}>
        <Heading variant="headingsmall" as="h1" mb={12}>My FGR</Heading>
        <img src={Logo} alt="FGR" sx={styles.logo} />
        <Heading variant="headingsmall" as="h2" mb={12}>Log in</Heading>
        {queryParams?.username &&
          <Alert variant='primary' mb={2}><p>Thanks for verifying your email address. You can now login below.</p></Alert>
        }
        {success &&
          <Alert variant='primary' mb={2}><p>{success}</p></Alert>
        }
        <Box sx={styles.intro}>
          <Text sx={styles.introText}>
            <Text as="strong">Wait! </Text>If you already have an online account with FGR, for ticketing or streaming live games, you will still need to create a My FGR account. 
          </Text>
          <Text sx={styles.introText}>
            <Link to="/news/welcome-to-the-new-fgr-website">Find out more</Link> about why you you need to create a new account.
          </Text>
        </Box>
        <Box as="form" sx={styles.form}>
          {resendVerification &&
            <Alert variant='error' mb={2}><Text>You haven't verified your email address. Please <a href="#" onClick={handleResendVerificationCode}>click here to resend your verification code</a></Text></Alert>
          }
          {error?.field === 'global' &&
            <Alert variant='error' mb={2}>{error?.value}</Alert>
          }
          <Box mb={6}>
            <Flex sx={{justifyContent: 'space-between'}} mb={2}>
              <Label>Email address</Label>
            </Flex>
            <Input type="email" name="email" autoComplete="username" value={state.email} onChange={handleChange} />
            <Error type="email" />
          </Box>
          <Box mb={10}>
            <Label mb={2}>Password</Label>
            <Input type="password" name="password" autoComplete='current-password' value={state.password} onChange={handleChange} />
            <Error type="password" />
          </Box>
          <Button variant="transparent" mb={4} onClick={handleSubmit}>Log in</Button>
          <Text sx={styles.link} as={Link} to='/reset-password'>Forgot your password?</Text>
        </Box>
        <Powered />

        {codeResent &&
          <React.Fragment>
            <Overlay onClick={() => setCodeResent(false)} />
            <Close onClick={() => setCodeResent(false)} />
            <Modal sx={{width: responsive('90%', '600px')}} scrollToTop>
              <CodeVerificationForm 
                username={state.email}
                onSuccess={handleCodeVerificationSuccess}
              />
            </Modal>
          </React.Fragment>
        }
      </Inner>
  )
}

export default LoginForm