const styles = {

  inner: {
    mt: '91px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    width: '138px',
    height: '138px',
    mb: 3
  },
  form: {
    width: '300px',
    margin: '0 auto',
    textAlign: 'center'
  },
  link: {
    fontWeight: 700,
    textDecoration: 'underline',
    color: 'black',
    fontSize: 0,
    display: 'block'
  },
  error: {
    color: 'red',
    textAlign: 'left',
    pt: 2,
  },
  intro: {
    maxWidth: '500px',
    margin: '0 auto',
    textAlign: 'center',
    mb: 4,

    'strong': {
      fontWeight: 700,
    }
  },
  introHeading: {
    fontSize: 5,
    lineHeight: 'body',
    fontWeight: 700,
  },
  introText: {
    fontSize: 3,
    lineHeight: 'body',
    mb: 2,

    'a': {
      color: 'inherit',
      fontWeight: 700,
    }
  }
}

export default styles